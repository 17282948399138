import { default as _91_46_46_46all_93JuCc232votMeta } from "/home/vsts/work/1/s/pages/[...all].vue?macro=true";
import { default as addressrujqHGhdoKMeta } from "/home/vsts/work/1/s/pages/account/address.vue?macro=true";
import { default as index1lyyw9BHOgMeta } from "/home/vsts/work/1/s/pages/account/index.vue?macro=true";
import { default as orderDX4eLhqynsMeta } from "/home/vsts/work/1/s/pages/account/order.vue?macro=true";
import { default as paymentrwrzUX9CCqMeta } from "/home/vsts/work/1/s/pages/account/payment.vue?macro=true";
import { default as profileBbJjt6uocOMeta } from "/home/vsts/work/1/s/pages/account/profile.vue?macro=true";
import { default as indexKcPdc90NnRMeta } from "/home/vsts/work/1/s/pages/account/recover/index.vue?macro=true";
import { default as passwordao3jT2RIa3Meta } from "/home/vsts/work/1/s/pages/account/recover/password.vue?macro=true";
import { default as cartG4O8VyJI55Meta } from "/home/vsts/work/1/s/pages/checkout/cart.vue?macro=true";
import { default as index9WXLyyUUZxMeta } from "/home/vsts/work/1/s/pages/checkout/index.vue?macro=true";
import { default as indexROdKX6YsW6Meta } from "/home/vsts/work/1/s/pages/checkout/success/[id]/index.vue?macro=true";
import { default as paidgRqAkVgI8aMeta } from "/home/vsts/work/1/s/pages/checkout/success/[id]/paid.vue?macro=true";
import { default as unpaidpGn6HrIveBMeta } from "/home/vsts/work/1/s/pages/checkout/success/[id]/unpaid.vue?macro=true";
import { default as logini6Htc8i9t2Meta } from "/home/vsts/work/1/s/pages/login.vue?macro=true";
import { default as newsletter_45subscribeH1tbji8A7PMeta } from "/home/vsts/work/1/s/pages/newsletter-subscribe.vue?macro=true";
import { default as registerbbeM7q8DAOMeta } from "/home/vsts/work/1/s/pages/register.vue?macro=true";
import { default as searchMkXoykAuUKMeta } from "/home/vsts/work/1/s/pages/search.vue?macro=true";
import { default as wishlisthZKOvsa3t3Meta } from "/home/vsts/work/1/s/pages/wishlist.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93JuCc232votMeta?.name ?? "all___de-DE",
    path: _91_46_46_46all_93JuCc232votMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93JuCc232votMeta || {},
    alias: _91_46_46_46all_93JuCc232votMeta?.alias || [],
    redirect: _91_46_46_46all_93JuCc232votMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: addressrujqHGhdoKMeta?.name ?? "account-address___de-DE",
    path: addressrujqHGhdoKMeta?.path ?? "/account/address",
    meta: addressrujqHGhdoKMeta || {},
    alias: addressrujqHGhdoKMeta?.alias || [],
    redirect: addressrujqHGhdoKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: index1lyyw9BHOgMeta?.name ?? "account___de-DE",
    path: index1lyyw9BHOgMeta?.path ?? "/account",
    meta: index1lyyw9BHOgMeta || {},
    alias: index1lyyw9BHOgMeta?.alias || [],
    redirect: index1lyyw9BHOgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: orderDX4eLhqynsMeta?.name ?? "account-order___de-DE",
    path: orderDX4eLhqynsMeta?.path ?? "/account/order",
    meta: orderDX4eLhqynsMeta || {},
    alias: orderDX4eLhqynsMeta?.alias || [],
    redirect: orderDX4eLhqynsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: paymentrwrzUX9CCqMeta?.name ?? "account-payment___de-DE",
    path: paymentrwrzUX9CCqMeta?.path ?? "/account/payment",
    meta: paymentrwrzUX9CCqMeta || {},
    alias: paymentrwrzUX9CCqMeta?.alias || [],
    redirect: paymentrwrzUX9CCqMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: profileBbJjt6uocOMeta?.name ?? "account-profile___de-DE",
    path: profileBbJjt6uocOMeta?.path ?? "/account/profile",
    meta: profileBbJjt6uocOMeta || {},
    alias: profileBbJjt6uocOMeta?.alias || [],
    redirect: profileBbJjt6uocOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: indexKcPdc90NnRMeta?.name ?? "account-recover___de-DE",
    path: indexKcPdc90NnRMeta?.path ?? "/account/recover",
    meta: indexKcPdc90NnRMeta || {},
    alias: indexKcPdc90NnRMeta?.alias || [],
    redirect: indexKcPdc90NnRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: passwordao3jT2RIa3Meta?.name ?? "account-recover-password___de-DE",
    path: passwordao3jT2RIa3Meta?.path ?? "/account/recover/password",
    meta: passwordao3jT2RIa3Meta || {},
    alias: passwordao3jT2RIa3Meta?.alias || [],
    redirect: passwordao3jT2RIa3Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: cartG4O8VyJI55Meta?.name ?? "checkout-cart___de-DE",
    path: cartG4O8VyJI55Meta?.path ?? "/checkout/cart",
    meta: cartG4O8VyJI55Meta || {},
    alias: cartG4O8VyJI55Meta?.alias || [],
    redirect: cartG4O8VyJI55Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: index9WXLyyUUZxMeta?.name ?? "checkout___de-DE",
    path: index9WXLyyUUZxMeta?.path ?? "/checkout",
    meta: index9WXLyyUUZxMeta || {},
    alias: index9WXLyyUUZxMeta?.alias || [],
    redirect: index9WXLyyUUZxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexROdKX6YsW6Meta?.name ?? "checkout-success-id___de-DE",
    path: indexROdKX6YsW6Meta?.path ?? "/checkout/success/:id()",
    meta: indexROdKX6YsW6Meta || {},
    alias: indexROdKX6YsW6Meta?.alias || [],
    redirect: indexROdKX6YsW6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/checkout/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: paidgRqAkVgI8aMeta?.name ?? "checkout-success-id-paid___de-DE",
    path: paidgRqAkVgI8aMeta?.path ?? "/checkout/success/:id()/paid",
    meta: paidgRqAkVgI8aMeta || {},
    alias: paidgRqAkVgI8aMeta?.alias || [],
    redirect: paidgRqAkVgI8aMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/checkout/success/[id]/paid.vue").then(m => m.default || m)
  },
  {
    name: unpaidpGn6HrIveBMeta?.name ?? "checkout-success-id-unpaid___de-DE",
    path: unpaidpGn6HrIveBMeta?.path ?? "/checkout/success/:id()/unpaid",
    meta: unpaidpGn6HrIveBMeta || {},
    alias: unpaidpGn6HrIveBMeta?.alias || [],
    redirect: unpaidpGn6HrIveBMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/checkout/success/[id]/unpaid.vue").then(m => m.default || m)
  },
  {
    name: logini6Htc8i9t2Meta?.name ?? "login___de-DE",
    path: logini6Htc8i9t2Meta?.path ?? "/login",
    meta: logini6Htc8i9t2Meta || {},
    alias: logini6Htc8i9t2Meta?.alias || [],
    redirect: logini6Htc8i9t2Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/login.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribeH1tbji8A7PMeta?.name ?? "newsletter-subscribe___de-DE",
    path: newsletter_45subscribeH1tbji8A7PMeta?.path ?? "/newsletter-subscribe",
    meta: newsletter_45subscribeH1tbji8A7PMeta || {},
    alias: newsletter_45subscribeH1tbji8A7PMeta?.alias || [],
    redirect: newsletter_45subscribeH1tbji8A7PMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: registerbbeM7q8DAOMeta?.name ?? "register___de-DE",
    path: registerbbeM7q8DAOMeta?.path ?? "/register",
    meta: registerbbeM7q8DAOMeta || {},
    alias: registerbbeM7q8DAOMeta?.alias || [],
    redirect: registerbbeM7q8DAOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/register.vue").then(m => m.default || m)
  },
  {
    name: searchMkXoykAuUKMeta?.name ?? "search___de-DE",
    path: searchMkXoykAuUKMeta?.path ?? "/search",
    meta: searchMkXoykAuUKMeta || {},
    alias: searchMkXoykAuUKMeta?.alias || [],
    redirect: searchMkXoykAuUKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/search.vue").then(m => m.default || m)
  },
  {
    name: wishlisthZKOvsa3t3Meta?.name ?? "wishlist___de-DE",
    path: wishlisthZKOvsa3t3Meta?.path ?? "/wishlist",
    meta: wishlisthZKOvsa3t3Meta || {},
    alias: wishlisthZKOvsa3t3Meta?.alias || [],
    redirect: wishlisthZKOvsa3t3Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/pages/wishlist.vue").then(m => m.default || m)
  }
]