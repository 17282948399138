<script setup lang="ts">
import { getSessionContext } from '@shopware-pwa/api-client';
import type { SessionContext } from '@shopware-pwa/types';
import { getPrefix } from './i18n/src/helpers/prefix';

/**
 * Init breadcrumbs context
 */
useBreadcrumbs();

const customCSS = ref('');
const customJS = ref('');

const { apiInstance } = useShopwareContext();
const sessionContextData = ref();
sessionContextData.value = await getSessionContext(apiInstance);

// Add custom Scripts and Styles
customCSS.value =
  sessionContextData.value.salesChannel.translated?.customFields?.custom_script_fields_css;
customJS.value =
  sessionContextData.value.salesChannel.translated?.customFields?.custom_script_fields_js;

// All this only for sticky header, that doesn't work correctly in desktop safari. probably because of lazy loading.
const isSafari = computed(() =>
  /^((?!chrome|android).)*safari/i.test(
    import.meta.client ? navigator.userAgent : 'safari',
  ),
);
const stickyHeaderCss = `@media screen and (min-width: 1080px){.is-sticky-hero,.cms-section-default.is-sticky-hero,.cms-section-wrapper.is-sticky-hero{position:sticky;top:0}.is-sticky-hero~.cms-section-default,.cms-section-default.is-sticky-hero~.cms-section-default,.cms-section-wrapper.is-sticky-hero~.cms-section-default{background:#fff;background-color:#fff;position:relative}.is-sticky-hero:has(.video-teaser),.cms-section-default.is-sticky-hero:has(.video-teaser),.cms-section-wrapper.is-sticky-hero:has(.video-teaser){margin-top:-10rem}}`;

useHead({
  title: 'super.natural',
  meta: [{ name: 'description', content: 'super.natural' }],
  link: [
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '180x180',
      href: '/favicons/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/favicon-16x16.png',
    },
    { rel: 'manifest', href: '/favicons/site.webmanifest' },
    {
      rel: 'mask-icon',
      href: '/favicons/safari-pinned-tab.svg',
      color: '#1d242d',
    },
    { rel: 'shortcut icon', href: '/favicons/favicon.ico' },
  ],
  htmlAttrs: {
    lang: 'de',
  },
  style: [
    {
      innerHTML: isSafari.value ? '' : stickyHeaderCss,
    },
  ],
  script: [
    {
      src: 'https://consent.cookiefirst.com/sites/supernatural-merino.com-444d7ab0-bb6f-4e44-8439-37db5c37ac14/consent.js',
      async: true,
      defer: true,
    },
    {
      id: 'mcjs',
      innerHTML:
        '!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/8c566562c954a28011297a164/710862e041cd3dcf7017d9464.js");',
    },
    {
      type: 'text/javascript',
      innerHTML: customJS.value,
      tagPosition: 'bodyClose',
    },
  ],
});

// If you enable runtimeConfig.shopware.useUserContextInSSR, then you can use this code to share session between server and client.

// const { data: sessionContextData } = await useAsyncData(
//   "sessionContext",
//   async () => {
//     return await getSessionContext(apiInstance);
//   }
// );

// read the locale/lang code from accept-language header (i.e. en, en-GB or de-DE)
// and set configuration for price formatting globally
const headers = useRequestHeaders();
// Extract the first (with highest priority order) locale or lang code from accept-language header
// for example: "en-US;q=0.7,en;q=0.3" will return "en-US"
const localeFromHeader = headers?.['accept-language']
  ?.split(',')
  ?.map(
    (languageConfig) => languageConfig.match(/^([a-z]{2}(?:-[A-Z]{2})?)/)?.[0],
  )
  .find(Boolean);

usePrice({
  currencyCode: sessionContextData.value?.currency?.isoCode || '',
  localeCode: localeFromHeader,
});

useSessionContext(sessionContextData.value as SessionContext);

const { getWishlistProducts } = useWishlist();
const { refreshCart } = useCart();

useNotifications();
useAddress();

const { locale, availableLocales, defaultLocale } = useI18n();
const router = useRouter();
const {
  getAvailableLanguages,
  getLanguageCodeFromId,
  getLanguageIdFromCode,
  changeLanguage,
  languages: storeLanguages,
} = useInternationalization();
const { languageIdChain, refreshSessionContext } = useSessionContext();

const { data: languages } = await useAsyncData('languages', async () => {
  return await getAvailableLanguages();
});

if (languages.value?.elements.length && router.currentRoute.value.name) {
  storeLanguages.value = languages.value?.elements;
  // Prefix from url
  const prefix = getPrefix(
    availableLocales,
    router.currentRoute.value.name as string,
    defaultLocale,
  );

  // Language set on the backend side
  const sessionLanguage = getLanguageCodeFromId(languageIdChain.value);
  // If languages are not the same, set one from prefix
  if (sessionLanguage !== prefix) {
    await changeLanguage(
      getLanguageIdFromCode(prefix ? prefix : defaultLocale),
    );
    await refreshSessionContext();
  }

  locale.value = prefix ? prefix : defaultLocale;
  // Set prefix from CMS components
  provide('urlPrefix', prefix);
}

onMounted(() => {
  refreshCart();
  getWishlistProducts();
});
</script>

<template>
  <Head>
    <Style type="text/css" :children="customCSS" />
  </Head>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
h2 {
  @apply py-4 text-4xl;
  line-height: 1.2;
}

select {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggc3Ryb2tlPScjNmI3MjgwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMS41JyBkPSdNNiA4bDQgNCA0LTQnLz48L3N2Zz4=');
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  appearance: none;
}

/* .page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
} */

/* @media screen and (min-width: 1080px) {
  .is-sticky-hero, .cms-section-default.is-sticky-hero, .cms-section-wrapper.is-sticky-hero {
    position: sticky;
    top: 0;
  
    ~ .cms-section-default{
      background: white;
      background-color: white;
      position: relative;
    }

    &:has(.video-teaser) {
      margin-top: -10rem;
    }
  }
} */
</style>
