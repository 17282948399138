<script setup lang="ts">
const props = defineProps<{
  error: {
    statusCode: number;
    statusMessage: string;
    message: string;
  };
}>();

const { t } = useI18n();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const errorMessageMap: { [key: number]: string } = {
  404: t('errorPages.404'),
  408: t('errorPages.408'),
  500: t('errorPages.500'),
  502: t('errorPages.502'),
  503: t('errorPages.503'),
};

const errorMessage =
  props.error.statusMessage ||
  errorMessageMap[props.error.statusCode as keyof typeof errorMessageMap];
</script>

<script lang="ts">
export default {
  name: 'ErrorPage',
};
</script>

<template>
  <div
    class="flex flex-col-reverse items-center justify-center px-5 py-3 md:px-32 md:py-20 lg:flex-row lg:px-24 lg:py-24"
  >
    <div class="my-8 flex flex-col items-center justify-center">
      <div class="max-w-md text-center">
        <h1 class="mb-8 text-9xl font-extrabold">
          <span class="sr-only">{{ $t('error') }}</span
          >{{ props.error.statusCode }}
        </h1>
        <p class="mb-8 mt-4 text-xl font-semibold md:text-3xl">
          {{ errorMessage }}
        </p>
        <p>
          {{ props.error.message }}
        </p>
        <NuxtLink
          :to="formatLink(`/`)"
          class="bg-brand-light hover:bg-brand-dark focus:ring-brand-primary w-full justify-center rounded-md border px-8 py-3 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 lg:w-auto"
        >
          {{ $t('goBackHome') }}
        </NuxtLink>
      </div>
    </div>
    <div class="flex items-center justify-center">
      <img v-lazy-load 
        class="max-h-md h-full w-full max-w-md"
        data-src="~/assets/error-background.png"
        alt="Error"
      />
    </div>
  </div>
</template>
